import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayPrice,
} from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  STOCK_MULTIPLE_ITEMS,
  STOCK_INFINITE_MULTIPLE_ITEMS,
  LISTING_STATE_PUBLISHED,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_DRAFT,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { createSlug, LISTING_PAGE_PARAM_TYPE_DRAFT, parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { ModalInMobile, PrimaryButton, AvatarSmall, H1, H2 } from '../../components';
import { Button, SecondaryButton } from '../../components';
import { InlineTextButton } from '../../components';
import { NamedLink } from '../../components';

import css from './OrderPanel.module.css';
import {
  LISTING_FIELD_DUE_DATE,
  LISTING_FIELD_REQUEST_LISTING_ID,
  LISTING_FIELD_RESPONSE_LISTING_ID,
  LISTING_TYPE_RESPONSE,
  LISTING_TYPE_REQUEST,
  SHOULD_ALWAYS_CREATE_NEW_LISTING,
} from '../../config/configListing';
import { initiateNegotiationMessage } from '../../containers/TransactionPage/TransactionPage.duck';
import { transitions } from '../../transactions/transactionProcessBooking';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  isRequest,
  onSubmit,
  onRequest,
  history,
  location,
  goToBooking
) => {
  if (goToBooking) {
    return goToBooking;
  }
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isRequest
    ? onRequest
    : isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
    isNegotiationTransaction,
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage
          id="OrderPanel.perUnit"
          values={{ unitType: isNegotiationTransaction ? 'item' : unitType }}
        />
      </div>
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      <div className={css.perUnit}>
        <FormattedMessage
          id="OrderPanel.perUnit"
          values={{ unitType: isNegotiationTransaction ? 'item' : unitType }}
        />
      </div>
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    onToggleFavorites,
    currentUser,
    currentUserListings,
    transactionId,
    paymentTransactionId,
    shouldKeepDefaultProcess,
    isNegotiableListing,
  } = props;
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);

  const negotiationTransactionId = queryParams.get('negotiationTransactionId');

  const routeConfiguration = useRouteConfiguration();
  const publicData = listing?.attributes?.publicData || {};
  const { listingType, unitType, transactionProcessAlias = '' } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const price = listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  const showPriceMissing = isPaymentProcess && !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName) && (!isNegotiableListing);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number' && !isNegotiableListing;

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const allowOrdersOfMultipleItems = [STOCK_MULTIPLE_ITEMS, STOCK_INFINITE_MULTIPLE_ITEMS].includes(
    listingTypeConfig?.stockType
  );

  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const isFavorite = currentUser?.attributes.profile.privateData.favorites?.includes(
    listing.id.uuid
  );

  const toggleFavorites = () => onToggleFavorites(isFavorite);

  const favoriteButton = isFavorite ? (
    <SecondaryButton
      className={css.favoriteButton}
      onClick={toggleFavorites}
    >
      <FormattedMessage id="OrderPanel.unfavoriteButton" />
    </SecondaryButton>
  ) : (
    <Button className={css.favoriteButton} onClick={toggleFavorites}>
      <FormattedMessage id="OrderPanel.addFavoriteButton" />
    </Button>
  );

  const responseListings = currentUserListings?.filter(
    listing => listing.attributes.publicData.listingType === LISTING_TYPE_RESPONSE
  );
  const publishedListings = responseListings?.filter(
    listing => listing.attributes.state === LISTING_STATE_PUBLISHED
  );
  const numPublishedListings = publishedListings?.length ?? 0;
  const numPendingApprovalListings =
    responseListings?.filter(listing => listing.attributes.state === LISTING_STATE_PENDING_APPROVAL)
      .length ?? 0;
  const draftListings = responseListings?.filter(
    listing => listing.attributes.state === LISTING_STATE_DRAFT
  );
  const numDraftListings = draftListings?.length ?? 0;

  const getRespondDesktopMessageID = () => {
    if (!currentUser) {
      return 'OrderPanel.respondByLogin';
    } else if (numPublishedListings > 1) {
      return 'OrderPanel.respondByChoosing';
    } else if (numPublishedListings === 1) {
      return 'OrderPanel.respond';
    } else if (numPendingApprovalListings) {
      return 'OrderPanel.respondByWaiting';
    } else if (numDraftListings) {
      return 'OrderPanel.respondByPublishing';
    } else {
      return 'OrderPanel.respondByCreating';
    }
  };
  const getRespondMobileMessageID = () => {
    if (!currentUser) {
      return 'OrderPanel.ctaButtonMessageRespondByLogin';
    } else if (numPublishedListings > 1) {
      return 'OrderPanel.ctaButtonMessageRespondByChoosing';
    } else if (numPublishedListings === 1) {
      return 'OrderPanel.ctaButtonMessageRespond';
    } else if (numPendingApprovalListings) {
      return 'OrderPanel.ctaButtonMessageRespondByWaiting';
    } else if (numDraftListings) {
      return 'OrderPanel.ctaButtonMessageRespondByPublishing';
    } else {
      return 'OrderPanel.ctaButtonMessageRespondByCreating';
    }
  };
  const getRespondLink = () => {
    const retval = { to: { search: '' }, params: {}, disabled: false };
    const priceParam = price ? `&priceAmount=${price.amount}` : '';
    if (!currentUser || SHOULD_ALWAYS_CREATE_NEW_LISTING) {
      retval.name = 'NewListingPage';
      retval.to.search = `?listingType=${LISTING_TYPE_RESPONSE}&${LISTING_FIELD_REQUEST_LISTING_ID}=${listing.id.uuid}${priceParam}`;
    } else if (numPublishedListings > 1) {
      retval.name = 'ManageListingsPage';
      retval.to.search = `?${LISTING_FIELD_REQUEST_LISTING_ID}=${listing.id.uuid}`;
    } else if (numPublishedListings === 1) {
      retval.name = 'RespondPage';
      retval.to.search = `?${LISTING_FIELD_REQUEST_LISTING_ID}=${listing.id.uuid}&${LISTING_FIELD_RESPONSE_LISTING_ID}=${publishedListings[0].id.uuid}`;
    } else if (numPendingApprovalListings) {
      retval.name = 'ListingPageCanonical';
      retval.params.id = listing.id.uuid;
      retval.disabled = true;
    } else if (numDraftListings) {
      retval.name = 'EditListingPage';
      retval.params = {
        id: draftListings[0].id.uuid,
        slug: 'draft',
        type: LISTING_PAGE_PARAM_TYPE_DRAFT,
        tab: 'photos',
      };
      retval.to.search = `?${LISTING_FIELD_REQUEST_LISTING_ID}=${listing.id.uuid}${priceParam}`;
    } else {
      retval.name = 'NewListingPage';
      retval.to.search = `?listingType=${LISTING_TYPE_RESPONSE}&${LISTING_FIELD_REQUEST_LISTING_ID}=${listing.id.uuid}${priceParam}`;
    }

    const pathname = pathByRouteName(retval.name, routeConfiguration, retval.params);
    retval.path = `${pathname}${retval.to.search}`;
    return retval;
  };

  const negotiateListing = async () => {
    const negotiationTransaction = await dispatch(initiateNegotiationMessage({
      processAlias: listing.attributes.publicData.transactionProcessAlias,
      transition: transitions.INQUIRE,
      params: {
        listingId: listing.id.uuid,
        protectedData: {
          inquiryMessage: "Hi, I would like negotiate the price of the booking"
        }
      }
    }));
    const negotiationTransactionId = negotiationTransaction.data.data.id.uuid;
    history.push("/order/"+negotiationTransactionId);
  };

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.modalHeading}>
          <H1 className={css.heading}>{title}</H1>
        </div>
        <div className={css.orderHeading}>
          {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        </div>
        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          isNegotiationTransaction={
            Boolean(transactionId ?? negotiationTransactionId) && !shouldKeepDefaultProcess
          }
        />
        <div className={css.author}>
          <AvatarSmall user={author} className={css.providerAvatar} />
          <span className={css.providerNameLinked}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
          </span>
          <span className={css.providerNamePlain}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
          </span>
          {!isOwnListing && onContactUser && <>
            <span className={css.linkSeparator}></span>
            <InlineTextButton
              rootClassName={css.contact}
              onClick={onContactUser}
              enforcePagePreloadFor="SignupPage"
            >
              <FormattedMessage id="UserCard.contactUser" />
            </InlineTextButton></>
          }
        </div>

        {favoriteButton}

        {showPriceMissing ? (
          <PriceMissing />
        ) : showInvalidCurrency ? (
          <InvalidCurrency />
        ) : showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="OrderPanelBookingTimeForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={params => {
              onFetchTransactionLineItems({
                ...params,
                negotiationTransactionId: negotiationTransactionId ?? transactionId,
              });
            }}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
            isNegotiationListing={Boolean(transactionId ?? negotiationTransactionId)}
            paymentTransactionId={paymentTransactionId}
            shouldKeepDefaultProcess={shouldKeepDefaultProcess}
          />
        ) : showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={params => {
              onFetchTransactionLineItems({
                ...params,
                negotiationTransactionId: negotiationTransactionId ?? transactionId,
              });
            }}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
            shouldKeepDefaultProcess={shouldKeepDefaultProcess}
          />
        ) : showProductOrderForm ? (
          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            currentStock={currentStock}
            allowOrdersOfMultipleItems={allowOrdersOfMultipleItems}
            pickupEnabled={pickupEnabled && displayPickup}
            shippingEnabled={shippingEnabled && displayShipping}
            displayDeliveryMethod={displayPickup || displayShipping}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={params => {
              onFetchTransactionLineItems({
                ...params,
                negotiationTransactionId: negotiationTransactionId ?? transactionId,
              });
            }}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
            isNegotiationTransaction={
              Boolean(transactionId ?? negotiationTransactionId) && !shouldKeepDefaultProcess
            }
            paymentTransactionId={paymentTransactionId}
          />
        ) : listingType === LISTING_TYPE_REQUEST || isNegotiableListing ? (
          publicData[LISTING_FIELD_DUE_DATE] < TODAY.getTime() ? (
            <p className={css.errorSidebar}>
              <FormattedMessage id="OrderPanel.requestListingDueDateError" />
            </p>
          ) : isOwnListing ? (
            <p className={css.errorSidebar}>
              <FormattedMessage id="OrderPanel.requestListingOwnListingError" />
            </p>
          ) : isNegotiableListing ? 
          (
            <PrimaryButton onClick={negotiateListing}>
              Negotiate
            </PrimaryButton>
          ) : (
            <NamedLink
              className={`${
                getRespondLink().disabled ? css.disabledLink : css.createNewListingButton
              }`}
              {...getRespondLink()}
            >
              <FormattedMessage id={getRespondDesktopMessageID()} />
            </NamedLink>
          )
        ) : showInquiryForm ? (
          <InquiryWithoutPaymentForm formId="OrderPanelInquiryForm" onSubmit={onSubmit} />
        ) : !isKnownProcess ? (
          <p className={css.errorSidebar}>
            <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
          </p>
        ) : null}
      </ModalInMobile>
      <div className={css.openOrderForm}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          showCurrencyMismatch
          isNegotiationTransaction={
            Boolean(transactionId ?? negotiationTransactionId) && !shouldKeepDefaultProcess
          }
        />

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : listingType === LISTING_TYPE_REQUEST &&
          publicData[LISTING_FIELD_DUE_DATE] < TODAY.getTime() ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.ctaButtonMessageDueDateError" />
          </div>
        ) : (
          <PrimaryButton
            onClick={handleSubmit(
              isOwnListing,
              isClosed,
              showInquiryForm,
              listingType === LISTING_TYPE_REQUEST,
              onSubmit,
              () => history.push(getRespondLink().path),
              history,
              location,
              Boolean(transactionId ?? negotiationTransactionId) && paymentTransactionId
                ? () => {
                    return history.push(
                      createResourceLocatorString(
                        'OrderDetailsPage',
                        routeConfiguration,
                        { id: paymentTransactionId, slug: createSlug(paymentTransactionId) },
                        {}
                      )
                    );
                  }
                : null
            )}
            disabled={
              isOutOfStock || (listingType === LISTING_TYPE_REQUEST && getRespondLink().disabled)
            }
          >
            {isBooking ? (
              <FormattedMessage
                id={
                  paymentTransactionId
                    ? 'BookingTimeForm.goToBooking'
                    : 'OrderPanel.ctaButtonMessageBooking'
                }
              />
            ) : isOutOfStock ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
            ) : isPurchase ? (
              <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
            ) : listingType === LISTING_TYPE_REQUEST ? (
              <FormattedMessage id={getRespondMobileMessageID()} />
            ) : (
              <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
            )}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  currentUserListings: state.user.currentUserListings,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(OrderPanel);
